#document-grid {
  height: auto;
  margin-top: 20px;
  max-width: 100vw;
  padding: 8px 0;

  .paper-grid-wrapper {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .error {
      position: absolute;
      z-index: 1;
      right: 10px;
      left: 10px;
    }

    .cover-wrapper {
      display: grid;
      align-items: center;
      height: 100px;
      text-align: center;

      .cover {
        height: 100px;
      }

      .cover:hover {
        position: absolute;
        top: 10px;
        margin-right: 10px;
        height: calc(100% - 20px);
        width: -webkit-fill-available;
        z-index: 1;
        transition: height 0.3s;
      }

      .loading-cover, .no-cover {
        margin: 0 auto;
      }
    }

    .box-description {
      word-break: break-word;
    }
  }

  .simple-element-grid {
    .box-description {
      .simple-additional-info {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
      }
    }
  }

  .load-more-button {
    margin: 10px 0 20px 0;
  }
}

@media (max-width: 400px) {
  #document-grid {
    .paper-grid-wrapper {
      .buttons-wrapper {
        flex-direction: column;

        .action-button {
          max-width: 100%;
        }
      }
    }
  }
}