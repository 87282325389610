#nav-bar {
    padding-left: 10px;

    .button-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        .piano-key {
            background-color: #ffcc00;
            height: 60px;
            border-radius: 0;
            margin: 0;
            cursor: pointer;
            transition: all 0.3s, transform 0.3s;
            box-shadow: inset 0 15px 20px -10px rgba(255, 255, 255, 0.3), inset 0 -15px 20px -10px rgba(255, 255, 255, 0.3);
            color: black;
        }

        .piano-key.active {
            -webkit-box-shadow: inset 0 0 17px 0 rgba(0, 0, 0, 0.4);
            -moz-box-shadow: inset 0 0 17px 0 rgba(0, 0, 0, 0.4);
            box-shadow: inset 0 0 17px 0 rgba(0, 0, 0, 0.4);
            transition: all 0.3s, transform 0.3s;
        }

        .piano-key:hover:not(.active) {
            -webkit-box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
            -moz-box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
            box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .padding-box {
        height: 40px;
        width: 100%;
        background-color: #121212;
    }

    .simple-ui-switch-wrapper {
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: 10px;
        background-color: #272727;
        border-radius: 0 0 24px 0;

        span {
            font-size: 10px;
        }
    }
}

@media (max-width: 500px) {
    #nav-bar {
        padding-left: 0;

        .button-wrapper {
            flex-wrap: nowrap;

            .piano-key {
                flex-grow: 1;
            }

            .welcome-text {
                display: none;
            }

            .simple-ui-switch-wrapper {
                bottom: -38px;
            }
        }
    }
}