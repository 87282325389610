#reader-box {
  position: relative;

  #epub-reader {
    .footer {
      position: absolute;
      bottom: 0;
      z-index: 1;
      background: rgba(0,0,0,0.7);
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }
  }

  #pdf-reader {
    display: flex;
    justify-content: center;
    overflow: auto;

    canvas {
      width: 100%;
    }

    .loading-screen {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer {
      position: absolute;
      bottom: 0;
      z-index: 1;
      background: rgba(0,0,0,0.7);
      width: calc(100% - 10px);
      display: flex;
      justify-content: space-between;
      padding: 5px;

      button {
        margin: 0 5px;
      }
    }

    .invert-color {
      filter: invert(1);
    }
  }
}