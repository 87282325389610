#search-page {
  padding-top: 5px;
  max-width: calc(100vw - 16px);

  .search-bar {
    background: rgb(18,18,18);
    position: sticky;
    top: 98px;
    z-index: 1;
    padding-bottom: 10px;

    .error-message {
      z-index: 3;
      position: absolute;
      right: 0;
      top: 10px;
      width: 100vw;
    }

    .search-bar-wrapper {
      position: relative;

      .search-bar-row {
        margin-top: 5px;
        overflow: hidden;
        min-height: 72px;
        transition: all 0.5s;
      }

      .search-bar-row-hidden {
        overflow: hidden;
        min-height: 0;
        height: 0;
        transition: all 0.5s;
      }

      .total-count-wrapper {
        height: 40px;
        position: absolute;
        bottom: -50px;
        right: -24px;
        padding: 0 10px;
        background-color: rgba(18, 18, 18, 0.8);
        border-radius: 0 0 0 24px;
        z-index: 2;
        display: flex;
        justify-content: center;

        svg {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .search-additional-text {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: grey;

    .search-additional-icon {
      margin-right: 10px;
      font-size: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    .search-bar {
      .search-bar-wrapper{
        .search-bar-row {
          height: 144px;
        }
      }
    }
  }
}
  