#login-page, #register-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .paper-wrapper {
    padding: 20px 0;
    width: min(500px, 90vw);

    .auth-text-field, .auth-button {
      margin: 0 20px;
    }

    .redirect-button {
      button {
        border: none !important;
      }
    }

    .redirect-button:hover {
      button {
        border: none !important;
      }
    }

    .successful-text {
      display: flex;
      justify-content: center;
      align-items: center;
      color: green;
      margin-top: 10px;

      svg {
        margin-right: 10px;
      }
    }
  }
}