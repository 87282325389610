body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

#root {
  .root-container {
    padding: 0;
    max-width: inherit;
  }
}
  